'use client';

import {useEffect, useState} from 'react';
import Image from 'next/image';
import Link from 'next/link';
import {useRouter} from 'next/navigation';
import {ProductWithLikes} from '@delorand/db/schema/product';
import {StoreWithFollowers} from '@delorand/db/schema/store';
import {ConvertDateToString} from '@delorand/schema/entities/shared';
import Button from '@delorand/ui/src/button';
import Avatar from '@delorand/ui/src/radix/avatar';
import {ShareSvg} from '@delorand/ui/src/svg/post';
import {HeartSvg} from '@delorand/ui/src/svg/shared';
import {getBaseUrl} from '@delorand/utils/api';
import {formatCurrency, getThumbnail, getVariant} from '@delorand/utils/shared';
import {CheckBadgeIcon} from '@heroicons/react/24/solid';
import {RWebShare} from 'react-web-share';
import {useStore} from '../../../store/store';
import {Follow} from '../../follow';

interface Props {
  product: ConvertDateToString<
    ProductWithLikes & {
      store: StoreWithFollowers;
    }
  >;
  index: number;
  addToCart: () => void;
  viewProduct: () => void;
  onFollow: () => void;
  onLike: () => void;
  userId: number | undefined;
  isAddingToCart: boolean;
}

const ProductPost = ({
  product,
  addToCart,
  viewProduct,
  onFollow,
  onLike,
  userId,
  isAddingToCart,
}: Props) => {
  const router = useRouter();

  const [liked, setLiked] = useState(false);

  useEffect(() => {
    if (product.productLikes.some(x => x.customerId === userId)) {
      setLiked(true);
    }
  }, [userId, product]);

  const onAfterAuth = useStore(state => state.onAfterAuth);

  const like = () => {
    if (!userId) {
      onAfterAuth(() => {
        setLiked(x => !x);
        onLike();
      });
      router.push('/login');
    } else {
      setLiked(x => !x);
      onLike();
    }
  };

  return (
    <div className="z-10 w-full bg-black">
      <div className="flex items-center gap-4 bg-black px-3 py-2">
        <Avatar
          className="h-[30px] w-[30px] rounded-full"
          alt={product.store.storeName}
          src={product.store.image}
        />
        <div className="flex-1">
          <Link
            prefetch={false}
            href={`/stores/${product.store.storeCode}`}
            className="flex items-center text-sm font-semibold"
          >
            {product.store.storeName}
            {product.store.verified && (
              <span className="ml-2">
                <CheckBadgeIcon width={16} color="white" />
              </span>
            )}
          </Link>
        </div>
        <Follow store={product.store} userId={userId} onToggle={onFollow} />
        {/* <button className=''>
          <EllipsisHorizontalIcon width={26} color='white' />
        </button> */}
      </div>
      <div className="relative flex w-full justify-center overflow-hidden">
        <Image
          alt={`product-${product.title}`}
          src={getThumbnail(product.images)}
          width={500}
          height={500}
          className="w-full object-cover"
          // priority={index < 2}
        />
      </div>
      <div className="bg-black px-5 pt-3 pb-5">
        <h4 className="text-[17px] font-medium">{product.title}</h4>
        <div className="mt-1 flex items-center justify-between">
          <div className="flex items-center gap-3 leading-5 font-medium">
            <p className="text-[16px]">
              {formatCurrency(product.variants?.[0]?.price)}
            </p>
            {product.discountPercentage > 0 && (
              <p className="text-custom-neutral text-[13px] line-through">
                {formatCurrency(
                  getVariant(product.variants).price *
                    ((100 + product.discountPercentage) / 100) -
                    1
                )}
              </p>
            )}
          </div>

          <div className="flex items-center gap-4 pr-2">
            <button onClick={like}>
              <HeartSvg
                fill={liked ? 'red' : 'transparent'}
                stroke={liked ? 'red' : 'white'}
              />
            </button>

            <RWebShare
              data={{
                text: `${product.title}`,
                url: `${getBaseUrl()}/item/${product.id}`,
                title: `DELORAND ${product.title?.toUpperCase()}`,
              }}
            >
              <button>
                <ShareSvg />
              </button>
            </RWebShare>
          </div>
        </div>
        <div className="mt-4 flex w-full items-center justify-between">
          <Button onClick={addToCart} isLoading={isAddingToCart} className="sm">
            Add To Cart
          </Button>
          <Button onClick={viewProduct} className="sm">
            View Product
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductPost;
